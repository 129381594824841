import axios from 'axios';
import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';

/**
 * Search Fab using Search Bar
 */
export const searchFab = async (value) => {
    let searchedResults = {};
    try {
        if (value.length >= 3){
            const response = await apiCall(APIUrlList.SEARCHFAB + `?search=${value}`, null, 'GET', true);
            searchedResults.data = response.data.data.data.map((value) => {
                return {
                    id: value.id,
                    name: value.name,
                    description: value.about_you,
                    image: value.profile_pic_url,
                    isfollowing: value.isfollowing,
                    surname: value.surname,
                    username: value.username,
                };
            });
            searchedResults.total = response.data.data.data.length;
            return searchedResults;
        }
    } catch (error){
        _sendResponse("error", error);
    }
};


/**
 * Search fab using Category and SubCategory
 */


export const searchFabCategory = async (category = null, subCategory = null) => {
    subCategory = JSON.stringify(subCategory).replace('[', '').replace(']','');
    let searchedResults = {};
    let url;
    if (category === ''){
        url = APIUrlList.SEARCHFAB;
    } else if (category !== null && subCategory == "null"){
        url = APIUrlList.SEARCHFAB + `?category_id=${category}`;
    } else if (category !== null && subCategory !== "null"){
        url = APIUrlList.SEARCHFAB + `?category_id=${category}&subcategory_id=${subCategory}`;
    }
    try {
        const response = await apiCall(url, null, 'GET', true);
        searchedResults = response.data.data;
        return searchedResults;
    } catch (error){
        _sendResponse("error", error);
    }
};

/**
 * Get sub category list
 */

export const subCategoryList = async (category_id = null, popular = false) => {
    let url = APIUrlList.CATEGORYLIST;
    if (category_id !== null){
        url = APIUrlList.CATEGORYLIST + `?category_id=${category_id}`;
    } else if (popular === true){
        url = APIUrlList.CATEGORYLIST + `?popular=1`;
    }
    let subCategories = [];
    try {
        const response = await apiCall(url, null, 'GET', false);
        if (category_id !== null){
            response.data.data[0].sub_category.map((value) => {
                let objToSend = {
                    text: value.sub_cat_name,
                    value: value.id,
                };
                subCategories.push(objToSend);
            });
        } else {
            response.data.data.map((value) => {
                let objToSend = {
                    text: value.name,
                    value: value.id,
                };
                subCategories.push(objToSend);
            });
            subCategories.unshift({text:'All', value:''});
        }

        return subCategories;
    } catch (error){
        _sendResponse('error', error);
    }

};
/**
 * search next page
 */

export const nextPage = async (url = null, category_id = null, sub_cat_id = null) => {
    try {
        if (url !== null){
            let requesturl = url;
            if (category_id != null && sub_cat_id != null) {
                requesturl = url+`&category_id=${category_id}&subcategory_id=${sub_cat_id}`;
            }
            if (category_id != null && sub_cat_id == null) {
                requesturl = url+`&category_id=${category_id}`;
            }
            var response =  await axios.get(requesturl, {headers: {Authorization: 'Bearer '+localStorage.getItem('user-token')}});
            return response.data.data;
        }
        return null;
    } catch (error){
        _sendResponse('error', error);
    }
};
/**
 * Handle follow and unfollow
 */

export const followFab = async (data) => {
    try {
        const response = await apiCall(APIUrlList.FOLLOWFAB, data, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }

};
