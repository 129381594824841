<template>
  <b-container class="py-md-4">
    <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3']">
      <b-row class="justify-content-center">
        <b-col
          xl="6"
          lg="8"
        >
          <div class="text-center pt-5 mb-5 text-center">
            <h1 class="title display-5 font-weight-bold mb-4">
              No results for {{ searchValue ? searchValue : 'found' }}
            </h1>
            <p class="font-open-sans mb-1">
              Haven’t found who you’re looking for?
            </p>
            <p class="font-open-sans mb-0">
              Tell us which celebrity should join FanPopz
            </p>
          </div>
          <b-row class="mb-5 justify-content-center">
            <b-col
              xl="8"
              lg="10"
            >
              <div class="font-open-sans">
                <div class="bank-search-input">
                  <b-input-group class="">
                    <b-form-input
                      id="fabs"
                      v-model="suggestedFab"
                      placeholder="Suggest your popz name"
                      type="text"
                      name="fabs"
                      required
                    />
                  </b-input-group>
                </div>
              </div>
              <b-overlay
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="mt-4"
              >
                <b-button
                  ref="button"
                  variant="dark"
                  class="btn-pop text-uppercase font-weight-bold"
                  squared
                  block
                  @click="handleSuggestFab"
                >
                  SEND
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
          <div class="text-right mb-5" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>


<script>
import style from "../../styles/celebsearch.module.scss";
import { addRequest } from '../../api/home';
import { _sendResponse } from '../../js/common-function';
export default {
    name: "GabSearchResults",
    props:{searchedValue:{type:String, default: ""}},
    data() {
        return {
            style,
            searchValue:"",
            suggestedFab: '',
        };
    },
    watch:{
        searchedValue: function(newVal){
            this.searchValue = newVal;
        }
    },
    created(){
        this.searchValue = this.searchedValue;
    },
    methods:{
        async handleSuggestFab(){
            if (this.suggestedFab){
                await addRequest('2', this.suggestedFab);
                this.suggestedFab = "";
            } else {
                _sendResponse('error', 'Please enter a popz name.');
            }
        }
    }
};
</script>
