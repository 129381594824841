<template>
  <div
    :class="[style['celeb-box'], 'text-center']"
  >
    <img
      :id="`celeb-profile${data.id}`"
      :src="data.profile_pic_url"
      :class="[style['searched-username'],'mt-3']"
      @error="handleError(`celeb-profile${data.id}`)"
      @click="handleRedirection(data.username)"
    >
    <div class="text-center ml-3 mr-3">
      <p
        :class="[style['searched-username'], 'font-weight-bold font-open-sans mb-0']"
        @click="handleRedirection(data.username)"
      >
        {{ data.name + ' ' + data.surname }}
      </p>
      <p
        class="font-weight-light font-open-sans mb-0 text-truncate"
      >
        {{ catName !== undefined ? catName : '' }} - <span
          v-for="(value, index) in subCategory"
          :key="index"
        >{{ value }}{{ subCategory.length>1 ? ',' : '' }}</span>
      </p>
      <p class="font-weight-light font-open-sans mb-3 text-truncate">
        {{ data.about_you !== "null" ? data.about_you : "" }}
      </p>
    </div>
    <div class="text-center">
      <b-link
        class="btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3"
        @click="handleFollow(data)"
      >
        {{ data.isfollowing === null || data.isfollowing.status === 0 ? "Follow" : "Unfollow" }}
      </b-link>
    </div>
  </div>
</template>

<script>
import style from '../../styles/celebsearch.module.scss';
import placeholderImge from '../../assets/download.jpeg';
export default {
    name:'CelebCards',
    props:{
        data:{
            type:Object,
            default:() =>{}
        },
        handleFollow:{
            type:Function,
            default: ()=>{}
        }
    },
    data(){
        return {
            style,
            subCategory:[],
            category:"",
            catName: ""
        };
    },
    created(){
        // let me = this
        if (this.data.categories !== undefined && this.data.categories.length> 0 && this.data.categories[0] !== undefined){
            this.catName = this.data.categories[0].cateogry && this.data.categories[0].cateogry.name ? this.data.categories[0].cateogry.name : "";
            for (var i=0; i<this.data.categories.length; i++){
                for (var j=0; j<this.data.categories.length; j++){
                    if (this.data.categories[i] && this.data.categories[i].cateogry && this.data.categories[i].cateogry.name && this.data.categories[i].cateogry.name === this.data.categories[j].cateogry.name) {
                        if (this.data.categories[i] && this.data.categories[i].sub_cateogry && this.data.categories[i].sub_cateogry.sub_cat_name){
                            this.subCategory.push(this.data.categories[i].sub_cateogry.sub_cat_name);
                            this.subCategory =  [...new Set(this.subCategory)];
                        }
                        
                    }
                }
            }
        }
    },
    methods:{
        handleRedirection(username){
            if (username != null){
                this.$router.push(`/feed/${username}`);
            }
            
        },
        handleError(data){
            let val = document.getElementById(data);
            if (val){
                document.getElementById(data).src = placeholderImge;
            }
        }
    }
};
</script>
