<template>
  <section>
    <b-row 
      cols="1"
      cols-sm="1" 
      cols-md="2" 
      cols-lg="3"
    >
      <b-col
        v-for="(data) in celebSearchResult" 
        :key="data.id"
        class="mt-3" 
      >
        <celeb-cards
          :data="data" 
          :handleFollow="handleFollow"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import style from "../../styles/celebsearch.module.scss";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { APIUrlList } from "../../js/api_url";
import CelebCards from './CelebCards.vue';
export default {
    name: "GabSearchResults",
    components:{ CelebCards },
    props: { 
        searchedResults:{ 
            type: Array, 
            default:()=>[] 
        },
        searchedValue:{ 
            type:String, 
            default: '', 
        } 
    },

    data() {
        return {
            style,
            celebSearchResult: [],
            followFlag:false
        };
    },
    watch: {
        searchedResults: function(newVal) {
            this.celebSearchResult = newVal;
        },
    },
    created() {
        this.celebSearchResult = [];
        this.celebSearchResult = this.searchedResults;
    },
    methods: {
        handleFollow(data) {
            this.followFlag = data.isfollowing === null || data.isfollowing.status === 0 ? false : true;
            let objToSend = {
                fab_id: data.id,
                status: data.isfollowing === null || data.isfollowing.status === 0 ? 1 : 0,
            };
            axios
                .post(API_BASE_URL + APIUrlList.FOLLOWFAB, objToSend, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("user-token"),
                    },
                })
                .then(() => {
                    let me = this;
                    if (this.followFlag){
                        this.followFlag = false;
                        this.searchedResults.findIndex(function(current, index){
                            if (current.id === data.id){
                                me.searchedResults[index].isfollowing.status = 0;
                            }
                        });
                    } else {
                        // this.$router.push(`/feed/${data.username}`);
                        window.location.href = `/feed/${data.username}`;
                    }
                });
        },
    },
};
</script>
