<template>
  <section>
    <loading-spinner v-if="loading" />
    <div :class="['py-md-4', style['celeb-wrapper']]">
      <div class="my-md-5 my-4 p-3">
        <b-row class="justify-content-center">
          <b-col
            xl="12"
            lg="13"
          >
            <b-row class="justify-content-center">
              <b-col 
                md="12" 
                class="text-center"
              >
                <b-input-group>
                  <template #append>
                    <b-input-group-text
                      :class="[
                        style['closeIcon'],
                        'bg-transparent',
                        'h-100',
                        'border-0',
                      ]"
                    >
                      <img
                        v-if="searchValue !== ''"
                        src="../assets/close.png"
                        alt=""
                        width="18"
                        @click="handleReset"
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="search"
                    placeholder="Start typing..."
                    size="lg"
                    :class="[style['gab-search-bar'], 'border-0', 'pt-3']"
                    name="search-bar"
                    @input="handleSearch"
                  />
                </b-input-group>
                <CelebList v-if="searching === false" />
                <b-row
                  v-if="searching === true && noResultsFound === false"
                  class="justify-content-center py-5 mb-4"
                >
                  <span
                    :class="[style['search-text'], 'mt-5']"
                  >{{ totalSearch }} search results</span>
                </b-row>
                <SearchedResults
                  v-if="searching === true && noResultsFound === false"
                  :searchedResults="searchedResults"
                />
                <NoResultsFound
                  v-if="noResultsFound"
                  :searchedValue="searchValue"
                />
              </b-col>
              <b-col md="3">
                <div>
                  <advertisement
                    v-for="(advertisement, aIndex) in advertisements"
                    :key="`advertisement${aIndex}`"
                    :advImage="advertisement.advImage"
                    :advText="advertisement.advText"
                    :advId="advertisement.advId"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-row />
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import style from "../styles/celebsearch.module.scss";
import SearchedResults from "../components/Celebrity/CelebSearchResults.vue";
import CelebList from "../components/Celebrity/CelebList.vue";
import NoResultsFound from "../components/Celebrity/NoResultsFound.vue";
import Advertisement from '../components/Advertisements/Advertisement.vue';
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../js/api_url";
import { advertisementsList } from '../api/feed';
import LoadingSpinner from '../components/Common/LoadingSpinner';
export default {
    name: "GabSearch",
    components: {
        SearchedResults,
        CelebList,
        NoResultsFound,
        Advertisement,
        LoadingSpinner
    },
    data() {
        return {
            style,
            searching: false,
            next_page_load: false,
            noResultsFound: false,
            searchedResults: [],
            totalSearch: 0,
            searchValue: "",
            advertisements:[],
            loading: false
        };
    },
    async created(){
        let advertisement = await advertisementsList();
        if (advertisement.length > 0){
            this.advertisements = advertisement;
        }
    },
    methods: {
        scroll() {
            var body = document.body,
                html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight );
            var middle_height = height / 2;
            if (window.scrollY > middle_height) {
                this.scrolledToBottom = true;
                this.pageRefresh = true;
                this.counter = this.counter + 1;
            }
        },
        handleSearch(value) {
            this.searchedResults = [];
            this.searchValue = value;
            if (value === "" || value.length < 3) {
                this.searching = false;
                this.noResultsFound = false;
            } else if (value.length < 15) {
                this.loading = true;
                this.searching = true;
                axios
                    .get(API_BASE_URL + APIUrlList.SEARCHFAB + `?search=${value}`, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("user-token"),
                        },
                    })
                    .then((response) => {
                        this.searchedResults = [];
                        if (response.data.status === false) {
                            this.noResultsFound = true;
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.searchedResults = [];
                            this.noResultsFound = false;
                            this.searchedResults = response.data.data.data;
                            this.totalSearch = response.data.data.data.length;
                        }
                    });
            }
        },
        handleReset(){
            document.getElementById('search').value = "";
            this.searching = false;
            this.noResultsFound = false;
        }
    },
};
</script>