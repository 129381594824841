<template>
  <section>
    <loading-spinner v-if="loading" />
    <h5 class="mb-4 text-center mt-4">
      I'm interested in:
    </h5>
    <b-form-group
      v-slot="{ ariaDescribedby }"
      class="mb-3"
    >
      <b-form-radio-group
        id="btn-radios-1"
        v-model="selectedCeleb"
        :options="celebList"
        :aria-describedby="ariaDescribedby"
        class="d-flex justify-content-center flex-wrap"
        name="radios-celebs"
        button-variant="primary-light rounded-pill mr-3 mb-0 px-4 flex-grow-0 "
        buttons
        @change="handleSubCategory"
      />
    </b-form-group>
    <h5
      v-if="subCategory.length > 0"
      class="mb-5 text-center mt-5"
    >
      Especially in
    </h5>
    <b-form-group
      v-slot="{ ariaDescribedby }"
      class="mb-3"
    >
      <b-form-checkbox-group
        id="btn-radios-1"
        v-model="subCat"
        :options="subCategory"
        :aria-describedby="ariaDescribedby"
        class="d-flex justify-content-center flex-wrap"
        name="radios-celebs"
        button-variant="primary-light rounded-pill mr-3 mb-3 px-4 flex-grow-0 "
        buttons
        @change="handleFabSearch"
      />
    </b-form-group>
    <no-results-found v-if="noResultsFound === true" />
    <b-row
      cols="1"
      cols-sm="1"
      cols-md="2"
      cols-lg="3"
    >
      <b-col
        v-for="(data) in celebs"
        :key="data.id"
        class="mt-3 px-2"
      >
        <celeb-cards
          :data="data"
          :handleFollow="handleFollow"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import style from "../../styles/celebsearch.module.scss";
import {API_IMAGE_URL } from "../../config/config";
import NoResultsFound from './NoResultsFound.vue';
import CelebCards from './CelebCards.vue';
import { searchFabCategory, subCategoryList, followFab, nextPage } from '../../api/celebritySearch';
import { getAdsDetail } from '../../api/feed';
import LoadingSpinner from '../Common/LoadingSpinner';
export default {
    name: "CelebList",
    components: { NoResultsFound, CelebCards, LoadingSpinner },
    data() {
        return {
            API_IMAGE_URL,
            style,
            selectedCeleb: "",
            celebList: [],
            subCategory: [],
            subCat:[],
            subId: "",
            catId: "",
            celebs: [],
            popularCeleb: "",
            selectCeleb: {},
            noResultsFound: false,
            followFlag: false,
            next_page_load: true,
            next_page_url: '',
            loading:false,
        };
    },
    watch:{
        celebs:function(newVal){
            this.celebs = newVal;
        }
    },
    async created() {
        this.loading = true;
        getAdsDetail();
        window.addEventListener("scroll", this.scroll);
        this.celebs = [];

        let fabList = await searchFabCategory('');
        if (fabList !== undefined){
            if (fabList.data.length > 0){
                this.loading = false;
                this.next_page_url = fabList.next_page_url;
                this.next_page_load = true;
                this.celebs = fabList.data;
            }
        }
        let categories = await subCategoryList();
        if (categories && categories.length > 0){
            this.celebList = categories;
        }
    },
    mounted(){
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scroll);
    },
    methods: {
        async scroll() {
            var body = document.body,
                html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight );
            var middle_height = height / 2;
            if (window.scrollY > middle_height) {
                this.scrolledToBottom = true;
                this.pageRefresh = true;
                this.counter = this.counter + 1;
                if (this.next_page_load === true){
                    this.next_page_load = false;
                    let fabList = await nextPage(this.next_page_url, this.catId);
                    if (fabList !== undefined && fabList !== null){
                        fabList.data.map(value => {
                            this.celebs.push(value);
                            this.next_page_url = fabList.next_page_url;
                            this.next_page_load = true;
                        });
                    }
                }
            }
        },
        async handleSubCategory(value) {
            this.loading = true;
            this.celebs = [];
            this.popularCeleb = "";
            this.subCategory = [];
            this.noResultsFound = true;
            this.next_page_url = null;
            this.catId = value;
            let fabList = await searchFabCategory(value);
            if (fabList && fabList.data.length > 0){
                this.noResultsFound = false;
                this.celebs = fabList.data;
                this.next_page_url = fabList.next_page_url;
                if (this.next_page_url !== null){
                    this.next_page_load = true;
                }
                this.loading = false;
                if (value !== ""){
                    this.loading = false;
                    let subCategories = await subCategoryList(value);
                    if (subCategories && subCategories.length > 0){
                        this.subCat = [];
                        this.subCategory = subCategories;
                    }
                }
            } else {
                this.loading = false;
            }
        },
        redirectToFeed(data){
            this.$router.push(`/feed/${data.username}`);
        },
        async handleFabSearch(value) {
            this.loading = true;
            this.noResultsFound = true;
            this.celebs = [];
            this.popularCeleb = "";
            let fabList = await searchFabCategory(this.catId, value);
            if (fabList && fabList.data.length > 0){
                this.noResultsFound = false;
                this.celebs = fabList.data;
                this.loading = false;
            } else {
                this.loading = false;
            }
        },
        async handleFollow(data) {
            this.followFlag = data.isfollowing === null || data.isfollowing.status === 0 ? false : true;
            let objToSend = {
                fab_id: data.id,
                status: data.isfollowing === null || data.isfollowing.status === 0 ? 1 : 0,
            };
            let follow = await followFab(objToSend);

            if (Object.keys(follow).length > 0){
                let me = this;
                if (this.followFlag){
                    this.followFlag = false;
                    this.celebs.findIndex(function(current, index){
                        if (current.id === data.id){
                            me.celebs[index].isfollowing.status = 0;
                        }
                    });
                }  else {
                    this.$router.push(`/feed/${data.username}`);
                }
            }
        },
    },
};
</script>
